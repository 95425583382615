<template>
  <div class="ml-5 mr-5">
    <div class="text-center">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <!-- <div class="text-center">
        <loading :active.sync="checkload" :can-cancel="false" loader="dots" color="#007bff" :height="45"
          :width="75" :is-full-page="fullPage"></loading>
      </div> -->
    <div class="text-center">
      <loading
        :active.sync="checkaddload"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <div class="text-left">
      <v-container fluid>
        <v-row class="no-gutters">
          <v-col cols="2">
            <!-- <v-text-field type="number" :min="0" v-model="factor" label="Factor"> </v-text-field> -->
          </v-col>
          <v-col cols="2">
            <!-- <v-menu ref="menu" v-model="menu" :return-value.sync="date" :close-on-content-click="false" max-width="290px"
                min-width="auto" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-on="on" v-bind="attrs" readonly v-model="date" label="Select Month And Year"
                    prepend-icon="mdi-calendar">
                  </v-text-field>
                </template>
                <v-date-picker type="month" v-model="date" :min="min" :max="max" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn @click="menu = false" text>cancel</v-btn>
                  <v-btn color="primary" @click="$refs.menu.save()" text>Save</v-btn>
                </v-date-picker>
              </v-menu> -->
          </v-col>
          <v-col cols="4" class="mt-5">
            <!-- <v-btn color="primary" small> calculate </v-btn> -->
          </v-col>
          <v-col cols="1" class="text-right mt-5"> </v-col>
          <v-col cols="3" class="text-right mt-5">
            <v-btn
              class="mr-4"
              color="primary"
              small
              :disabled="check_flag"
              :loading="check_flag"
              v-show="checkIfOperationExistForModule('view')"
              @click="show_participations();"
              >Show Participations
            </v-btn>
            <v-btn
              color="primary"
              class="mr-0"
              v-show="checkIfOperationExistForModule('add')"
              small
              @click="add_record()"
              >Add</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="tableWrapper">
              <v-data-table
                :loading="isLoading"
                :headers="headers"
                :items="items"
                item-key="year"
                :page.sync="page"
                :options.sync="options"
                class="elevation-1"
                :server-items-length="totalrecs"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template v-slot:item.actions="{ item }">
                  <v-row class="no-gutters">
                    <v-col class="text-left">
                      <v-icon
                        class="mr-4"
                        @click="update_record(item)"
                        title="Edit"
                        v-show="checkIfOperationExistForModule('edit')"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        @click="deletefunc(item)"
                        title="Delete"
                        v-show="checkIfOperationExistForModule('delete')"
                        >mdi-delete</v-icon
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:top>
                  <v-dialog
                    v-if="dialog"
                    v-model="dialog"
                    persistent
                    max-width="800px"
                  >
                    <v-card elevation="1" max-height="900px">
                      <v-toolbar flat dense color="primary">
                        <v-spacer></v-spacer>
                        <v-toolbar-title
                          ><span style="color: white">{{
                            formname
                          }}</span></v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <div class="text-right pa-2">
                          <v-icon
                            v-show="isLoading === false"
                            color="white"
                            @click="close_dialog()"
                          >
                            mdi-close
                          </v-icon>
                        </div>
                      </v-toolbar>
                      <v-form ref="form" @submit.prevent="
                        submit_record();
                      " method="post" v-model="valid" enctype=multipart/form-data>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-select
                                style="font-size: 14px"
                                :label="label_year"
                                v-model="default_items.m_year"
                                :items="years_list"
                                chips
                                small-chips
                                item-text="val"
                                item-value="db_val"
                                persistent-hint
                                return-object
                              >
                              </v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                style="font-size: 14px"
                                :label="label_month"
                                v-model="default_items.m_month"
                                :items="months_list"
                                chips
                                small-chips
                                item-text="val"
                                item-value="db_val"
                                persistent-hint
                                return-object
                              >
                              </v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                style="font-size: 14px"
                                :label="label_mchannel"
                                :loading="mchannel_load"
                                v-model="default_items.mChannelName"
                                chips
                                small-chips
                                :items="mChannels"
                                item-text="val"
                                item-value="db_val"
                                persistent-hint
                                return-object
                              >
                              </v-select>
                            </v-col>
                            <v-col
                              ><v-select
                                style="font-size: 14px"
                                :label="label_sector"
                                v-model="default_items.s1_sectorName"
                                :loading="checkaddload"
                                :items="sectors"
                                item-text="val"
                                item-value="db_val"
                                chips
                                small-chips
                                persistent-hint
                                return-object
                                :rules="sectorRules"
                              >
                              </v-select
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              ><v-select
                                style="font-size: 14px"
                                :label="label_category"
                                v-model="default_items.s2_sectorName"
                                :items="cats"
                                item-text="val"
                                :loading="checkaddload"
                                chips
                                small-chips
                                item-value="db_val"
                                persistent-hint
                                return-object
                                @change="getSubCat()"
                              >
                              </v-select
                            ></v-col>
                            <v-col>
                              <v-select
                                style="font-size: 14px"
                                :label="label_subcategory"
                                :loading="sub_load"
                                v-model="default_items.s3_sectorName"
                                chips
                                small-chips
                                :items="subcats"
                                item-text="val"
                                item-value="db_val"
                                persistent-hint
                                return-object
                              >
                              </v-select>
                            </v-col>
                            <v-col>
                              <v-autocomplete
                                v-model="default_items.companyName"
                                :items="companies"
                                :loading="comp_load"
                                :search-input.sync="search_company"
                                hide-no-data
                                hide-selected
                                item-text="val"
                                item-value="db_val"
                                :label="label_company"
                                placeholder="Enter minimum 2 characters"
                                prepend-icon="mdi-magnify"
                                deletable-chips
                                chips
                                small-chips
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-select
                                style="font-size: 14px"
                                :loading="checkaddload"
                                :label="label_country"
                                v-model="default_items.countryName"
                                chips
                                small-chips
                                :items="countries"
                                item-text="val"
                                item-value="db_val"
                                persistent-hint
                                return-object
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="3">
                              <v-text-field
                                type="number"
                                chips
                                small-chips
                                :min="0"
                                :max="999999"
                                v-model="default_items.multiplier"
                                label="Multiplier"
                                :rules="multiplier_rules"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-spacer></v-spacer>
                          <v-btn type="submit" small color="primary">
                            save
                          </v-btn>
                          <v-btn color="grey" small @click="
                            close_dialog()
                            ">
                            cancel
                          </v-btn>

                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
              <div class="text-center pt-4 pb-4">
                <Pagination
                  :isloading="isLoading"
                  :startRecord="startRecord"
                  :currentPage="currentPage"
                  :lastPage="lastPage"
                  :lastRecord="lastRecord"
                  :totRecords="totRecords"
                  :isCurrentPageClass="isCurrentPageClass"
                  :perpage="perpage"
                  :getLastPageClass="getLastPageClass"
                  :totPage="totPage"
                  :getPages="getPages"
                  @handlePerPage="handlePerPage"
                  @paginate="paginate"
                  @last="last"
                  @getDataByPage="getDataByPage"
                  :showPerPage="showPerPage"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-dialog
          v-model="part_dialog"
          persistent
          max-width="1000px"
          max-height="auto"
        >
          <v-container style="background-color: white" fluid>
            <v-toolbar flat dense>
              <v-tabs v-model="tab" color="primary" grow slider-size="2">
                <v-tab v-for="(item, index) in tab_item" :key="index">{{
                  item.tab
                }}</v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-icon color="primary" @click="part_dialog = false">
                mdi-close
              </v-icon>
            </v-toolbar>
            <v-row>
              <v-col cols="1" md="1"> </v-col>
              <v-col cols="10" md="10">
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="(item, index) in tab_item" :key="index">
                    <v-card elevation="1" max-height="auto" max-width="auto">
                      <v-data-table
                        elevation="1"
                        class="mb-12"
                        :loading="isLoading"
                        :headers="item.headers"
                        :items="item.items"
                        :server-items-length="13"
                        hide-default-footer
                        item-key="date"
                      >
                        <template v-slot:top>
                          <v-toolbar flat dense>
                            <v-spacer></v-spacer>
                            <v-toolbar-title
                              ><span>{{ item.tab }}</span></v-toolbar-title
                            >
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </template>
                      </v-data-table>
                      <v-spacer></v-spacer>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                <!-- <v-card elevation="1" max-height="auto" max-width="auto">
                    <v-data-table elevation="1" :loading="isLoading" :headers="em_headers" :items="em_items"
                      :server-items-length="13" hide-default-footer item-key="date">
                      <template v-slot:top>
                        <v-toolbar flat dense>
                          <v-spacer></v-spacer>
                          <v-toolbar-title><span>Electronic Mail Panelist Participations</span></v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-card> -->
              </v-col>
              <v-col cols="1" md="1"> </v-col>
            </v-row>
          </v-container>
        </v-dialog>
      </v-container>
      <v-dialog v-model="deletedialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="text-h4 pa-16">
            <b>Do you really want to delete record?</b>
          </v-card-title>

          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="delete_rec()"> Yes </v-btn>
            <v-btn color="grey" small @click="deletedialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";

export default {
  components: { Loading, Pagination },
  data() {
    return {
      delete_item: null,
      deletedialog: false,
      tab: null,
      flag: false,
      tab_item: [
        {
          tab: "Direct Mail Panelist Participations",
          headers: [
            {
              text: "Date",
              value: "date",
              sortable: false,
              width: "60",
              class: "v-data-table-header",
            },
            {
              text: "Consumer",
              value: "consumer",
              sortable: false,
              width: "60",
              class: "v-data-table-header",
            },
            {
              text: "Employer/Business Owner",
              value: "busssiness",
              sortable: false,
              width: "150",
              class: "v-data-table-header",
            },
            {
              text: "Insurance Producer/Financial Advisor",
              value: "insurance",
              sortable: false,
              width: "150",
              class: "v-data-table-header",
            },
          ],
          items: [],
        },
        {
          tab: "Electronic Mail Panelist Participations",
          headers: [
            {
              text: "Date",
              value: "date",
              sortable: false,
              width: "60",
              class: "v-data-table-header",
            },
            {
              text: "Consumer",
              value: "consumer",
              sortable: false,
              width: "60",
              class: "v-data-table-header",
            },
            {
              text: "Employer/Business Owner",
              value: "busssiness",
              sortable: false,
              width: "150",
              class: "v-data-table-header",
            },
            {
              text: "Insurance Producer/Financial Advisor",
              value: "insurance",
              sortable: false,
              width: "150",
              class: "v-data-table-header",
            },
          ],
          items: [],
        },
      ],
      checkload: false,
      checkaddload: false,
      part_dialog: false,
      factor: 1.88,
      itemToUpdate: null,
      sectorRules: [(value) => !!value || "Required."],
      formname: null,
      valid: true,
      debounce: null,
      countries: [],
      search_company: null,
      selected_year: {},
      selected_month: {},
      sub_load: false,
      comp_load: false,
      mchannel_load: false,
      menu: false,
      dialog: false,
      date: new Date().toISOString().substr(0, 7),
      min: moment(new Date()).subtract(10, "years").format("YYYY-MM-DD"),
      max: moment(new Date()).format("YYYY-MM-DD"),

      headers: [
        {
          text: "Year",
          value: "m_year",
          sortable: false,
          width: "60",
          class: "v-data-table-header",
        },
        {
          text: "Month",
          value: "m_month",
          sortable: false,
          width: "60",
          class: "v-data-table-header",
        },
        {
          text: "Media Channel",
          value: "mChannelName",
          sortable: false,
          width: "140",
          class: "v-data-table-header",
        },
        {
          text: "Primary Sector",
          value: "s1_sectorName",
          sortable: false,
          width: "140",
          class: "v-data-table-header",
        },
        {
          text: "Primary Category",
          value: "s2_sectorName",
          sortable: false,
          width: "160",
          class: "v-data-table-header",
        },
        {
          text: "Primary Sub Category",
          value: "s3_sectorName",
          sortable: false,
          width: "180",
          class: "v-data-table-header",
        },
        {
          text: "Company",
          value: "companyName",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Country",
          value: "countryName",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Multiplier",
          value: "multiplier",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
      ],
      items: [],
      options: {},
      totalrecs: 0,
      showPerPage: true,
      //
      //pagination code
      page: 1,
      pageCount:0,
      perpage: 50,
      totRecords: null,
      isLoading: true,
      fullPage: true,
      showPerPage: false,

      reset_items: {
        companyName: {},
        countryName: {},
        m_month: {},
        m_year: {},
        multiplier: 0.0,
        s1_sectorName: {},
        s2_sectorName: {},
        s3_sectorName: {},
      },
      default_items: {
        mChannelName: {},
        companyName: {},
        countryName: { db_val: 1, val: "USA" },
        m_month: { db_val: 0, val: "All" },
        m_year: { db_val: 0, val: "All" },
        multiplier: null,
        s1_sectorName: { db_val: 372, val: "Automotive" },
        s2_sectorName: { db_val: 0, val: "All" },
        s3_sectorName: { db_val: 0, val: "All" },
      },
      edited_items: {
        companyName: {},
        countryName: {},
        m_month: {},
        m_year: {},
        multiplier: 0.0,
        s1_sectorName: {},
        s2_sectorName: {},
        s3_sectorName: {},
      },
      sectors: [],
      cats: [],
      subcats: [],
      companies: [],
      mChannels: [],
      label_year: "Year",
      label_month: "Month",
      label_sector: "Primary Sector",
      label_category: "Primary Category",
      label_subcategory: "Primary Sub Category",
      label_company: "Company",
      label_country: "Country",
      label_mchannel: "Media Channel",
      years_list: null,
      months_list: null,
    };
  },
  computed: {
    multiplier_rules: function () {
      return [
        (val) => val < 999999 || "Max value should be less than 999999",
        (val) => val != null || "Value Required",
        (val) => val != '' || "Value Required",
        (val) => val >= 0 || "Negative is not valid",
        function (val) {
          if (val != null) {
            let check = val.toString();
            if(check.length > 7){
              return "Total length should be less than 7"
            }
            check = check.split(".");
            if (check.length > 1) {
              return (
                check[1].length < 4 || "Only three digits after decimal required"
              );
            }
          }
          return true;
        },
      ];
    },
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    endIndex: function () {
      if (this.page == 1) {
        return this.perpage;
      } else {
        return this.perpage * this.page;
      }
    },
    startIndex: function () {
      if (this.page == 1) {
        return this.perpage * 0;
      } else {
        return this.endIndex - this.perpage;
      }
    },
    check_flag() {
      if (this.flag === true) {
        return true;
      } else {
        return false;
      }
    },
    selected_years: {
      get() {
        const date = new Date(moment().format("MM-DD-YYYY"));
        const year = date.getFullYear();
        let years = [];
        years.push({ db_val: 0, val: "All" });
        for (let index = 2009; index <= parseInt(year); index++) {
          years.push({ val: index, db_val: index });
        }
        return years;
      },
      set(val) {
        this.years_list = val;
      },
    },
    selected_months: {
      get() {
        const monthsArray = moment.months();
        let months = [];
        months.push({ db_val: 0, val: "All" });
        for (let index = 0; index < monthsArray.length; index++) {
          months.push({ val: monthsArray[index], db_val: index + 1 });
        }
        return months;
      },
      set(val) {
        this.months_list = val;
      },
    },
  },
  methods: {
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getReport(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getReport(this.page);
    },
    paginate(n) {
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getReport(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getReport(this.page);
    },
    async getReport(page = "") {
      let check = this;
      let crrpage = this.page - 1;
      let mail_vol_url =
        check.$url("admin_report_url") + "get_mail_volume_report";
      // let mail_vol_url = "http://10.0.0.206:5409/get_mail_volume_report";

      check.isLoading = true;
      if (page != "") {
        crrpage = page - 1;
      }
      let data = {
        page: crrpage,
        per_page: this.perpage,
      };
      try {
        const res = await this.$fetch({
          requiresAuth: true,
          operation: "view",
          vueScope: check,
        }).post(mail_vol_url, data);
        check.isLoading = false;
        if (res.data.report.length) {
          let result_data = [];
          res.data.report.forEach(function (item) {
            result_data.push(item);
          });
          this.items = result_data;
          this.totalrecs = res.data.report_length;
          this.totRecords = res.data.report_length;
          if (this.totRecords <= this.perpage) {
            this.page = 1;
          }
        } else {
          this.items = res.data.report;
          this.totalrecs = res.data.report_length;
          this.totRecords = res.data.report_length;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async dialog_func() {
      if (this.formname == "Add multiplier") {
        await (this.sectors = []);
        await (this.cat = []);
        await (this.subcats = []);
        await (this.default_items.countryName = {});
        await (this.default_items.m_month = {});
        await (this.default_items.m_year = {});
        await (this.default_items.s1_sectorName = {});
        await (this.default_items.s2_sectorName = {});
        await (this.default_items.s3_sectorName = {});
        await (this.default_items.multiplier = null);
      }
    
      // this.years_list = this.selected_years
      // this.months_list = this.selected_months
      let check = this;
      if (this.checkaddload) return;
      check.checkaddload = true;
      this.dialog = true;
      let sectors_url = check.$url("admin_report_url") + "get_sectors_and_cat";
      // let sectors_url = "http://10.0.0.206:5409/get_sectors_and_cat";
      this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
        .post(sectors_url, {})
        .then((res) => {
          if (res.data.sectors.length) {
            this.sectors = res.data.sectors;
            this.cats = res.data.categories;
            this.countries = res.data.countries;
          } else {
            this.sectors = [];
            this.cats = [];
            this.countries = [];
          }
          check.checkaddload = false;
        })
        .catch((error) => {
          console.error(error);
        });

      this.getMediaChannels()
    },
    getSubCat() {
      this.subcats = [];
      let check = this;
      check.sub_load = true;
      let subcat_url = check.$url("admin_report_url") + "get_subcats";
      // let subcat_url = "http://10.0.0.206:5409/get_subcats";
      let data = {
        id: this.default_items.s2_sectorName.db_val,
      };
      this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
        .post(subcat_url, data)
        .then((res) => {
          if (res.data.subcats.length) {
            this.subcats = res.data.subcats;
          } else {
            this.subcats = [];
          }
          this.subcats.unshift({ db_val: 0, val: "All" });
          check.sub_load = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getMediaChannels() {
      this.mChannels = [];
      let check = this;
      check.mchannel_load = true;
      let mchannel_url = check.$url("admin_report_url") + "get_mchannel";
      let data = {};
      this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
        .post(mchannel_url, data)
        .then((res) => {
          if(res.data.mChannelName?.length){
            this.mChannels = res.data.mChannelName
          }
          check.mchannel_load = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    validate_func() {
      let res = this.$refs.form.validate();
      console.log(res);
      return res;
    },
    submit_record() {
      if (this.formname == "Edit multiplier") {
        this.update_rec();
      } else {
        this.insert_new_rec();
      }
    },
    insert_new_rec() {
      if (!this.validate_func()) {
        return;
      }
      let check = this;
      if (this.checkaddload) return;
      if (this.comp_load) return;
      let insert_url = check.$url("admin_report_url") + "insert_record";
      // let insert_url = "http://10.0.0.206:5409/insert_record";
      this.checkaddload = true;
      let data = this.default_items;
      this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
        .post(insert_url, data)
        .then((res) => {
          if (res.data.errCode == 0) {
            this.checkaddload = false;
            this.$swal.fire({
              icon: "success",
              title: "Record Inserted Successfully",
            });
            this.dialog = false;
          } else {
              if((res.data.msg.match('Duplicate entry')).length > 0){
                this.$swal.fire({
                icon: "error",
                title: "Duplicate entry",
              });
            }
              else{
                this.$swal.fire({
                icon: "error",
                title: "Some Error Occurred",
              });
              }
              this.checkaddload = false;
              return
          }
          this.default_items = this.reset_items;
          this.companies = [];
          this.sectors = [];
          this.cats = [];
          this.countries = [];
          this.getReport();
        })
        .catch((error) => {
          this.checkaddload = false;
          this.dialog = false;
          console.error(error);
        })
    },
    add_record() {
      this.formname = "Add multiplier";
      this.dialog_func();
    },

    update_record(item) {
      const monthsArray = moment.months();
      const month_num = monthsArray.indexOf(item.m_month) + 1;
      this.formname = "Edit multiplier";
      this.dialog_func();
      this.itemToUpdate = item;
      // this.default_items = this.reset_items
      // this.sectors = [{ db_val: item.m_sectorID, val: item.s1_sectorName }];
      // this.cats = [{ db_val: item.m_categoryID, val: item.s2_sectorName }];
      // this.countries = [{ db_val: item.countryID, val: item.countryName }];
      // this.subcats = [{ db_val: item.m_subcategoryID, val: item.s3_sectorName }];
      this.companies = item.m_companyID
        ? [{ db_val: item.m_companyID, val: item.companyName }]
        : [];
      // this.years_list = this.selected_years;
      // this.months_list = this.selected_months;
      this.default_items = {
        mChannelName: { db_val: item.m_mchannelID, val: item.mChannelName },
        companyName: { db_val: item.m_companyID, val: item.companyName },
        countryName: { db_val: item.m_countryID, val: item.countryName },
        m_month: {
          db_val: item.m_month === "All" ? 0 : month_num,
          val: item.m_month,
        },
        m_year: {
          db_val: item.m_year === "All" ? 0 : parseInt(item.m_year),
          val: item.m_year,
        },
        multiplier: item.multiplier,
        s1_sectorName: { db_val: item.m_sectorID, val: item.s1_sectorName },
        s2_sectorName: { db_val: item.m_categoryID, val: item.s2_sectorName },
        s3_sectorName: {
          db_val: item.m_subcategoryID,
          val: item.s3_sectorName,
        },
      };
      this.getSubCat();
      this.dialog = true;
    },
    update_rec() {
      if (!this.validate_func()) {
        return;
      }
      let check = this;
      if (this.checkaddload) return;
      if (this.comp_load) return;
      let update_url = check.$url("admin_report_url") + "update_record";
      // let update_url = "http://10.0.0.206:5409/update_record";
      this.checkaddload = true;
      let data = { filters: this.itemToUpdate, data: this.default_items };
      this.$fetch({ requiresAuth: true, operation: "edit", vueScope: check })
        .post(update_url, data)
        .then((res) => {
          if (res.data.errCode == 0) {
            this.$swal.fire({
              icon: "success",
              title: "Record Updated Successfully",
            });
          } else {
              if((res.data.msg.match('Duplicate entry')).length > 0){
                this.$swal.fire({
                icon: "error",
                title: "Duplicate entry",
              });
            }
              else{
                this.$swal.fire({
                icon: "error",
                title: "Some Error Occurred",
              });
              }
              this.checkaddload = false;
              return
          }
          this.default_items = this.reset_items;
          this.companies = [];
          this.sectors = [];
          this.checkaddload = false;
          this.dialog = false;
          this.cats = [];
          this.countries = [];
          this.getReport();
        })
        .catch((error) => {
          this.checkaddload = false;
          this.dialog = false;
          console.error(error);
        })
    },
    delete_rec() {
      this.deletedialog = false;
      let check = this;
      if (this.isLoading) return;
      if (this.comp_load) return;
      let delete_url = check.$url("admin_report_url") + "delete_record";
      // let delete_url = "http://10.0.0.206:5409/delete_record";
      this.checkaddload = true;
      let data = this.delete_item;
      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: check })
        .post(delete_url, data)
        .then((res) => {
          if (res.data.errCode == 0) {
            this.$swal.fire({
              icon: "success",
              title: "Record Deleted Successfully",
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Some Error Occurred",
            });
          }
          this.checkaddload = false;
          this.getReport();
        })
        .catch((error) => {
          console.error(error);
          this.checkaddload = false;
        });
    },
    /*
    async show_participations() {
      let check = this;
      let direct_mail_part_url =
        check.$url("admin_report_url") + "direct_mail_participations";
      let email_part_url =
        check.$url("admin_report_url") + "email_participations";
      // let direct_mail_part_url = "http://10.0.0.206:5409/direct_mail_participations";
      // let email_part_url = "http://10.0.0.206:5409/email_participations";
      let direct_mail = [];
      let electronic_mail = [];
      // check.tab_item[0].items = []
      // check.tab_item[1].items = []
      //for (i; i < ranges.length; i++) {
      // const [from, to] = ranges[i];
      // let data = { from: from, to: to };
      await this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: check,
      })
        .post(direct_mail_part_url, {})
        .then((res) => {
          if (res.data.errCode == 0) {
            direct_mail.push(...res.data.direct_mail);
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Some Error Occurred",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });

      await this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: check,
      })
        .post(email_part_url, {})
        .then((res) => {
          if (res.data.errCode == 0) {
            electronic_mail.push(...res.data.electronic_mail);
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Some Error Occurred",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
      direct_mail.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
      electronic_mail.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
      check.tab_item[0].items = direct_mail;
      check.tab_item[1].items = electronic_mail;
      this.flag = false;
    }, */
    async show_participations() {
      let check = this;
      let i = 0
      let direct_mail_part_url = check.$url("admin_report_url") + "direct_mail_participations";
      let email_part_url = check.$url("admin_report_url") + "email_participations";
      // let direct_mail_part_url = "http://10.0.0.206:5409/direct_mail_participations";
      // let email_part_url = "http://10.0.0.206:5409/email_participations";
      if (check.tab_item[0].items.length > 0 && check.tab_item[1].items.length){
        check.part_dialog = true
        return
      }
      let direct_mail = []
      let electronic_mail = []
      // check.tab_item[0].items = []
      // check.tab_item[1].items = []
      const ranges = [
        [0, 3],
        [3, 6],
        [6, 9],
        [9, 12]
      ];
      check.flag = true
      for (i; i < ranges.length; i++) {
        const [from, to] = ranges[i];
        let data = { from: from, to: to };
        await this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
            .post(direct_mail_part_url, data)
            .then((res) => {
              if (res.data.errCode == 0) {
                direct_mail.push(...res.data.direct_mail)

              }

            })

        await this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
              .post(email_part_url, data)
              .then((res) => {
                if (res.data.errCode == 0) {
                  electronic_mail.push(...res.data.electronic_mail)
                } 

              })
      }
      if (i > 0) {
        direct_mail.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        electronic_mail.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        check.tab_item[0].items = direct_mail
        check.tab_item[1].items = electronic_mail
      }
      check.part_dialog = true
      check.flag = false
    },
    close_dialog() {
      this.default_items = {
        companyName: {},
        countryName: {},
        m_month: {},
        m_year: {},
        multiplier: 0.0,
        s1_sectorName: {},
        s2_sectorName: {},
        s3_sectorName: {},
      };
      this.companies = [];
      this.subcats = [];
      this.sectors = [];
      this.cats = [];
      this.countries = [];
      this.dialog = false;
    },
    deletefunc(item) {
      this.delete_item = item;
      this.deletedialog = true;
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
  },
  watch: {
    search_company(message) {
      if (message === null) return;
      if (this.isLoading) return;
      if (this.comp_load) return;
      let view = this;
      view.companies = [];
      if (message.length >= 2) {
        clearTimeout(view.debounce);
        view.debounce = setTimeout(() => {
          let comp_url = view.$url("admin_report_url") + "get_companies";
          // let comp_url = "http://10.0.0.206:5409/get_companies";
          let data = {
            message: message,
          };
          view.comp_load = true;
          view
            .$fetch({ requiresAuth: true, operation: "add", vueScope: view })
            .post(comp_url, data)
            .then((res) => {
              if (res.data.companies.length) {
                view.companies = res.data.companies;
              } else {
                view.companies = [];
              }
              view.comp_load = false;
            })
            .catch((error) => {
              console.error(error);
            });
        }, 300);
      }
    },
  },
  created() {
    this.years_list = this.selected_years;
    this.months_list = this.selected_months;
    this.getReport()
  },
};
</script>
